<template>
  <div class="livePageWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              河南省团干部网络教育平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </header>
    <el-main class="main-content">
      <div>
        <el-row type="flex">
          <el-col class="leftContent">
            <div class="grid-content bg-purple-light grid-content-left">
              <div class="leftSide">
                <img src="../../assets/signSideNav.png" alt="" />
                <div>签到</div>
              </div>
              <div class="navAndSign">
                <div class="returnIcon" @click="$router.back(-1)">
                  < &nbsp;返回
                </div>
                <div class="title">签到</div>
                <div class="signWrap">
                  <div class="sign" @click="submitSignFun"   v-show="isSignin == 'N'">
                    <img
                      src="../../assets/sign.png"
                      alt=""
                    /><div class="nosignText">签到</div>
                  </div>

                  <div class="sign" v-show="isSignin == 'Y'">
                    <img
                      src="../../assets/nosign.png"
                      alt=""
                    /><div>已签到</div>
                  </div>
                   <!-- 签退，高亮 -->
                  <div class="signout" @click="submitSignOutFun" v-show="this.isSignout == 'N' && isSignin == 'Y'">
                    <img
                      v-show='isSignBtnShow==true'
                      src="../../assets/signout.png"
                      alt=""
                    />
                    <div v-show='isSignBtnShow==true' class="nosignoutText">签退</div>
                  </div>
                  <!-- 已签退 -->
                  <div class="signout" @click="submitSignOutFun" v-show="this.isSignout == 'Y' && isSignin == 'Y'">
                    <img
                      src="../../assets/nosignout.png"
                      alt=""
                    />
                    <div v-show='isSignBtnShow==false'>已签退</div>
                  </div>
                  <!-- 签退，灰色 -->
                  <div class="signout" v-show="this.isSignout == 'N' && isSignin == 'N'">
                    <img
                      src="../../assets/nosignout.png"
                      alt=""
                    />
                    <div class="nosignoutText_gray">签退</div>
                  </div>

                </div>
              </div>
            </div>
          </el-col>
          <el-col class="RightContent">
            <div class="grid-content bg-purple-light">
              <div class="chapterTitle">{{ chapterTitle }}</div>
              <div>
                <img
                  class="courseCover"
                  :src="courseCover_url"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-main>
    <footer></footer>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  computed: {
    // 控制签退是否显示与是否可点击
    showSignOutType () {
      return true
      // let newDate = new Date()
      // let endTime = new Date(this.endTime)
      // // 时间已到，课程已经结束
      // if (newDate > endTime) {
      //   return true
      // } else {
      //   return false
      // }
    },
    isSignBtnShow () {
      return this.isSignout == 'N' /// / 没有签退
    },
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      timer: null,
      courseCover_url: '',
      isSignin: '',
      isSignout: '',
      chapterTitle: '',
      endTime: '',
      isShowSignout: false,
    }
  },
  methods: {

    pageJumpLogin () {
      this.$router.push('/login')
    },
    // 获取local中用户信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },

    // 提交签到
    submitSignFun () {
      //   if (this.isSignin == 'Y') return
      this.$axios
        .post(this.$apiUrl.saveLiveChapterSignin, { id: this.$route.params.id })
        .then((response) => {
          let res = response.data
          if (res.success) {
            this.isSignin = 'Y'
            this.$message({
              type: 'success',
              message: '签到成功',
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
            })
          }
        })
    },
    // 提交签退
    submitSignOutFun () {
      this.$axios
        .post(this.$apiUrl.saveLiveChapterSignout, {
          id: this.$route.params.id,
        })
        .then((response) => {
          let res = response.data
          if (res.success) {
            this.isSignout = 'Y'
            this.$message({
              type: 'success',
              message: '签退成功',
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
            })
          }
        })
    },
    watchTime () {
      this.timer = setInterval(() => {
        let newDate = new Date()
        let endTime = new Date(this.filterCourseInfo.endTime)
        this.isShowSignout = newDate > endTime
        console.log(this.isShowSignout)
        if (this.isShowSignout) {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 5000)
    },
    // 获取此章节的信息，包括签到，签退，直播id，背景图片
    getChapterInfo () {
      this.$axios.get(this.$apiUrl.loadStudentLiveChapter, {params: {id: this.$route.params.id}}).then(response => {
        let res = response.data
        console.log(res)
        if (res && res.code === 200 && res.success) {
          this.courseCover_url = res.data.courseCover_url ? res.data.courseCover_url : require('../../assets/tuanBg.png')
          this.isSignin = res.data.isSignin
          this.isSignout = res.data.isSignout
          this.chapterTitle = res.data.chapterTitle
          this.endTime = res.data.endTime
          let newDate = new Date()
          let endTime = new Date(this.endTime)
          this.isShowSignout = newDate > endTime
          console.log(this.isShowSignout)
          if (!this.isShowSignout) {
            this.watchTime()
          }
        }
      })
    }
  },
  mounted () {
    this.getChapterInfo()
    this.getUserInfo()
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = null
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style scoped lang="scss">
.livePageWrap {
  position: relative;
  min-height: 100vh;
  background-color: #fafafa;
  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 42px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1440px;
    margin: 0 auto;
    line-height: 42px;
    padding: 0 100px;

    .header-left-content {
      font-size: 20px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 33px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 30px;
        line-height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      ::v-deep .el-dropdown {
        font-size: 14px;
        color: #4774df;
        background-color: #fff;
        height: 30px;
        line-height: 30px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }
  .main-content {
    width: 1440px;
    padding-top: 0px;
    margin: 0 auto;
    .grid-content-left {
      display: flex;
      .leftSide {
        width: 74px;
        height: 699px;
        background: #2f3a4a;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        color: #ffff;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        padding-top: 10px;
        img {
          width: 26px;
          height: 26px;
        }
      }
      .navAndSign {
        height: 699px;
        background: #ffffff;
        .returnIcon {
          padding: 24px 0px 30px 22px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }
        .returnIcon:hover {
          color: #a4c2fa;
        }
        .title {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border-bottom: 1px solid rgba(112, 112, 112, 0.2);
          padding: 9px 18px;
        }
      }
      .signWrap {
        width: 249px;
        height: 593px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
          padding: 30px 0px;
          color: "#000000";
          display: flex;
          flex-direction: column;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
          > img {
            width: 50px;
            height: 50px;
          }
        }
        .nosignText {
          color: #1c4ecc;
        }
        .nosignoutText {
          color: #ff6f00;
        }
        .nosignoutText_gray{
          color:#a3a3a3
        }
      }
    }
    .RightContent {
      flex: 1;
      padding: 30px 0px;
      .chapterTitle {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 14px;
      }
    }
    .courseCover {
      width: 1045px;
      height: 620px;
    }
  }
  footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background: url("../../assets/image/homeImg/headerBg.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
